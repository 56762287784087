/* Settings Page animation*/

.settings_page.page-animate-enter {
  @apply transition-all duration-500 ease-in-out
}

.settings_page.page-animate-enter-from {
  @apply transform translate-y-16 opacity-0
}

.settings_page.page-animate-enter-to {
  @apply transform translate-y-0 opacity-100
}

.settings_page.page-animate-leave {
  @apply transition-all duration-300
}

.settings_page.page-animate-leave-from {
  @apply transform translate-y-0 opacity-100
}

.settings_page.page-animate-leave-to {
  @apply transform translate-y-16 opacity-0
}

.nav-logo.settings_page {
  @apply text-blue-500;
}

.nav-bg.settings_page {
  @apply h-80 bg-blue-300;
}

.nav-bg.settings_page .nav-bg-pattern {
  @apply opacity-20;
}
