@import "_fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  /*@apply bg-gradient-to-br from-gray-100 via-white to-white bg-no-repeat;*/
  @apply bg-gray-100;

  margin: 0;
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, #root {
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  .placeholder-italic::placeholder{
    @apply italic
  }
}

.root {
  position: relative;
}

ul.highlights {
  text-indent: -20px;
  list-style: none;
  list-style-position: outside;
}

ul.highlights li:before {
  @apply text-orange-300;
  content: '✓';
  font-family: initial;
  margin-right: 8px;
}

.swiper {
  width: 100%;
  height: auto;
}

.sentence-case::first-letter { text-transform: uppercase }

.text-highlighted {
  @apply bg-gradient-to-r;
  background-repeat: no-repeat;
  background-position: 0 70%;
  background-size: 100% 45%;
  /*
  background-size: 0 45%;
  animation: highlighttext 7s infinite;*/
  display: inline;
}

@keyframes highlighttext {
  50% {background-size: 100% 45%;}
}

.logo-white-orange .equipt_logo .text{
  color: white !important;
}
.logo-white-orange .equipt_logo .check{
  @apply text-orange !important;
}
