.nav-logo.auth_page .equipt_logo .text {
  fill: #4668DC;
}

.nav_notifications.confirm_page {
  @apply hidden;
}

.nav_notifications.reset_page {
  @apply hidden;
}
/*.auth_buttons {
  @apply transition-opacity;
}*/

.auth_page .auth_buttons {
  @apply opacity-0 pointer-events-none;
}
