/* Landing Page designs*/
.home_page .auth_buttons-register {
  @apply bg-blue
}


.content_page_heading {
  @apply uppercase font-heading font-bold block text-5xl md:text-6xl mb-6;
}

.content_subtitle {
  @apply uppercase font-heading block font-bold text-base md:text-lg mb-1;
}

.content_heading-gray {
  @apply content_heading text-gray-600;
}

.content_heading {
  @apply uppercase font-heading font-bold block leading-none text-3xl md:text-5xl mb-6;
}

.content_body {
  @apply text-base md:text-lg leading-6;
}

.content_body-gray {
  @apply text-gray-700 content_body;
}

.past-event-swiper {
  overflow: visible !important;
}

.swiper-button-prev, .swiper-button-next  {
  @apply text-gray-400;
}

.sponsored_event_tile-title {
  @apply text-xl lg:text-3xl text-center md:text-left md:pr-10;
}
