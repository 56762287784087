.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary-blob {
  --fill: #fdd25d;
}

.button {

}
.placeholder-italic::placeholder{
  @apply italic
}

.input {
  @apply placeholder-italic appearance-none block w-full px-3 py-2 bg-gray-100 border-0 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm;
}

.input.input-primary {
  @apply focus:ring-brand-primary focus:border-brand-primary;
}

.input.input-has-error {
  @apply border border-red-500 ring-red-500
}

.input-hide-errors .input-error {
  @apply opacity-0
}

.btn {
  @apply text-center font-bold font-button text-sm text-white uppercase px-6 py-3 flex justify-center border border-transparent rounded-full shadow-sm outline-none focus:outline-none focus:ring-2 focus:ring-offset-2
}

.btn-sm {
  @apply text-sm px-6 py-1
}

.btn.btn-primary {
  @apply bg-brand-primary hover:bg-orange-600 focus:ring-orange-300
}

.btn.btn-primary-white {
  @apply text-orange bg-white hover:bg-orange-200 focus:ring-orange-400
}

.btn.btn-secondary {
  @apply bg-brand-secondary hover:bg-blue-600 focus:ring-blue-300
}

.btn.btn-floating {
  @apply shadow-floating;
}

.btn.btn-secondary.btn-disabled {
  @apply bg-blue-200 pointer-events-none
}

.btn.btn-secondary-white {
  @apply text-blue bg-white hover:bg-blue-200 focus:ring-blue-400
}

.btn.btn-secondary-white-border {
  @apply text-blue bg-white hover:bg-blue-200 focus:ring-blue-400 border-2 border-blue-500
}

.btn.btn-primary-white-border {
  @apply text-orange bg-white hover:bg-orange-100 focus:ring-orange-400 border border-orange-200
}

.btn.btn-gray-white-border {
  @apply text-gray-500 bg-white hover:bg-gray-100 focus:ring-gray-400 border border-gray-200
}

.btn.btn-white-black {
  @apply text-black bg-white hover:bg-gray-100 focus:ring-gray-400 border border-gray-200
}

.btn.btn-color {
  @apply outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform transform hover:scale-95;
}

.tile-btn {
  @apply relative tracking-widest text-center font-bold text-sm uppercase px-6 py-2 flex justify-center rounded-md shadow-sm outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 transform hover:scale-95;
}

.job-apply-btn {
  @apply relative tracking-widest text-center font-bold text-xs uppercase pl-10 pr-12 py-2 flex justify-center rounded shadow-sm outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform transform hover:scale-95;
}

.job-apply-btn-slim {
  @apply relative tracking-widest text-center font-bold text-xs uppercase pl-10 pr-12 py-1 flex justify-center rounded shadow-sm outline-none focus:outline-none focus:ring-2 focus:ring-offset-2 transition-transform transform hover:scale-95;
}

.tile-btn-branded {
  @apply border border-transparent;
}

/*
.tile-btn::before{
  @apply rounded-md opacity-0 transition-opacity border border-black bg-black absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tile-btn:hover::before{
  @apply opacity-20
}
*/

.equipt_logo .check{
  fill:currentColor
}
.equipt_logo .text{
  fill: white
}

.nav-logo{
  @apply text-orange-500;
}

.nav-logo.organizations_page {
  @apply text-white;
}

.nav-logo.privacy_page .equipt_logo .text {
  fill: #4668DC;
}

.nav-logo.terms_page .equipt_logo .text {
  fill: #4668DC;
}

.nav-logo.cookie_page .equipt_logo .text {
  fill: #4668DC;
}

.nav-bg-pattern {
  transition-property: opacity;
  transition-duration: 500ms;
  @apply bg-gradient-to-r from-transparent to-black opacity-40
}

.nav-bg {
  transition-property: background-color, height;
  transition-duration: 500ms;
  @apply ease-in-out h-0 bg-blue-500 opacity-100;
}

.nav-bg.course_page {
  @apply h-0 bg-transparent;
}

.nav-bg.organizations_page {
  @apply h-0 bg-transparent;
}

.nav-bg.organizations_page .nav-bg-pattern {
  @apply opacity-20;
}

.btn.btn-sm {
  @apply px-4 py-2 text-xs
}

.btn.btn-group {
  @apply px-4 py-2.5 text-xs
}

#root {
  background: transparent;
}

.organization_course-tile {
  min-height: 450px;
}

/* Smaller Course Tile */
.course-tile-sm_body, .course-tile_body {
  @apply flex-col items-end h-56 flex justify-end;
}

.course-tile-sm_content, .course-tile_content {
  @apply w-full flex flex-col;
}

.course-tile-sm_register, .course-tile_register {
  @apply w-full;
}

.course-tile-sm_instructor, .course-tile_instructor {
  @apply mb-3 mt-auto;
}

/* Large Course Tile */

.course-tile .course-tile_body {
  @apply sm:h-40 sm:flex-row;
}

.course-tile .course-tile_content {
  @apply sm:w-2/3;
}

.course-tile .course-tile_register {
  @apply sm:w-1/3;
}

.course-tile .course-tile_instructor {
  @apply sm:mb-0;
}


.course-tile .course-tile_title {
  @apply text-2xl font-semibold mt-2 ;
  /*h-16 flex items-end*/
}

.course-tile.col-span-1 .course-tile_body {
  @apply course-tile-sm_body;
}

.course-tile.col-span-1 .course-tile_content {
  @apply course-tile-sm_content;
}

.course-tile.col-span-1 .course-tile_register {
  @apply course-tile-sm_register;
}

.course-tile.col-span-1 .course-tile_instructor {
  @apply course-tile-sm_instructor;
}

.dashboard-job-application:hover{
  outline-color: rgba(228, 228, 231, 1);
  outline-width: 1px;
  outline-style: solid;
}


/* Page Animations */
.page-animate-enter {
  @apply transition-opacity duration-500 ease-in-out
}

.page-animate-enter-from {
  @apply opacity-100
}

.page-animate-enter-to {
  @apply opacity-100
}

.page-animate-leave {
  @apply transition-all duration-300
}

.page-animate-leave-from {
  @apply opacity-100
}

.page-animate-leave-to {
  @apply opacity-100
}

/* Login Page animation*/

.page-animate-enter #auth-form {
  @apply transition-all duration-500 ease-in-out
}

.page-animate-enter-from #auth-form{
  @apply transform translate-y-16 opacity-0
}

.page-animate-enter-to #auth-form{
  @apply transform translate-y-0 opacity-100
}

.page-animate-leave #auth-form{
  @apply transition-all duration-300
}

.page-animate-leave-from #auth-form{
  @apply transform translate-y-0 opacity-100
}

.page-animate-leave-to #auth-form{
  @apply transform translate-y-16 opacity-0
}

/* Input */

.inline-placeholder.inline-placeholder-center label{
  @apply justify-center ml-0
}

.inline-placeholder.inline-placeholder-center input{
  @apply text-center
}

.inline-placeholder label {
  @apply absolute top-0 text-white w-full h-0 flex justify-start ml-3 uppercase
}

.inline-placeholder {
  position: relative;
}

.inline-placeholder input{
  @apply placeholder-opacity-0
}

.inline-placeholder label::before {
  @apply text-sm font-medium text-gray-500 text-center italic rounded-md bg-transparent inline uppercase;
  content: attr(title);
  white-space: nowrap;
  line-height: 36px;
  height: 36px;
  transition: 300ms all;
  pointer-events: none;
}


.inline-placeholder input:not(:placeholder-shown) + label::before {
  @apply bg-white text-blue-500 font-semibold;
  font-size: 12px;
  display: inline;
  margin-top: -10px;
  line-height: 18px;
  height: 18px;
  background: white;
  padding: 0 6px;
}

.inline-placeholder input:not(:placeholder-shown).input.input-has-error + label::before{
  @apply text-red-500
}

.inline-auth .auth_header {
  display: none;
}

.nav-container {
  @apply absolute w-full
}
