/* Dashboard Page animation*/

.dashboard_page.page-animate-enter {
  @apply transition-all duration-500 ease-in-out
}

.dashboard_page.page-animate-enter-from {
  @apply transform translate-y-16 opacity-0
}

.dashboard_page.page-animate-enter-to {
  @apply transform translate-y-0 opacity-100
}

.dashboard_page.page-animate-leave {
  @apply transition-all duration-300
}

.dashboard_page.page-animate-leave-from {
  @apply transform translate-y-0 opacity-100
}

.dashboard_page.page-animate-leave-to {
  @apply transform translate-y-16 opacity-0
}


.nav-bg.dashboard_page {
  @apply h-56;
}

.dashboard_page .sponsored_event_tile-title {
  @apply text-2xl md:text-left md:pr-10;
}
