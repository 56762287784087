.create-event_cover .filepond--root,
.create-event_cover .filepond--root .filepond--drop-label,
.create-organization_cover .filepond--root,
.create-organization_cover .filepond--root .filepond--drop-label{
  height: 208px;
  min-height: 208px;
}

.create-organization_logo .filepond--root,
.create-organization_logo .filepond--root .filepond--drop-label {
  height: 160px;
  min-height: 160px;
}